@import '../../styles/customMediaQueries.css';
.heroContainer {
  background-image: url('../../assets/landing-bg.jpg');
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 24px 50px;
  position: relative;
  min-height: 50vh;
  display: flex;
  align-items: center;
  position: relative;
  @media (--viewportMedium) {
    min-height: 70vh;
    background-position: center;
  }
  &::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorBlack);
    content: '';
    left: 0;
    top: 0;
    opacity: 0.2;
  }
  & .contentWidth {
    position: relative;
    z-index: 1;
    max-width: 1023px;
    width: 100%;
    margin: 0px auto;
    & .heroContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      & > h1 {
        color: var(--colorWhite);
        margin: 0 0 24px 0;
      }
      & > p {
        color: var(--colorWhite);
        margin: 0;
      }
      & .heroSearchSec {
        width: 100%;
        margin-top: 26px;
        & .landingSearchForm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          max-width: 100%;
          margin: 0 auto;

          @media (--viewportSmall) {
            max-width: 90%;
          }
          @media (--viewportMedium) {
            max-width: 60%;
          }
          @media (--viewportLarge) {
            flex-wrap: nowrap;
            max-width: 100%;
          }
          & .locationSearch {
          }
        }
      }
    }
  }
}
.title {
  composes: h1 from global;
}
.sectionSubHeading {
  composes: sectionSubHeading from global;
}

.animatedText {
      font-size: 35px;
      line-height: 110%;
      color: var(--colorWhite);
      font-weight: var(--fontWeightBold);
      margin-bottom: 4px;
      margin-right: 4px;
}
